import React, { useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";

import CookieBanner from "../components/CookieBanner";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Versions from "../components/Versions/Versions";
import Newsletter from "../components/common/Newsletter";
import SeoUpdates from "../components/SeoUpdates";

import { blogCategories } from "../helpers/constants";

const UpdatesPage = (props) => {
  const { data, pageContext } = props;

  const posts = data.allMarkdownRemark.edges;

  const [category, setCategory] = useState(pageContext.category || "All posts");

  return (
    <>
      <SeoUpdates category={category === "All posts" ? null : category} />
      <Header color="bg-primary text-black" />
      <div className="text-white bg-black">
        <div className="container flex flex-col w-full gap-5 pt-5 pb-20 h-fit md:gap-8 xl:gap-8 md:pt-10 xl:pt-10">
          <div className="flex flex-col justify-between gap-5 md:flex-row xl:flex-row ">
            <h1 className="text-2xl text-center md:text-2xl xl:text-3xl">Get more out of Slashscore</h1>
            <div className="flex justify-end order-first mr-2 updates-header-dropdown md:order-last xl:order-last">
              <select
                value={category}
                name="blogCategory"
                onChange={(e) => {
                  if (e.target.value === "All posts") navigate("/updates");
                  else navigate(`/updates/${e.target.value.toLowerCase()}`);
                  setCategory(e.target.value);
                }}
                className="px-2 text-black border-2 border-black rounded-md"
              >
                {blogCategories.map((option) => (
                  <option key={option.id} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-10 mb-8 md:grid-cols-3 md:gap-12">{posts.map((article, index) => {
            const postContent = article.node.frontmatter;
            const image = getImage(article.node.frontmatter.featuredImage);
            return (
              <div className="flex flex-col gap-3" key={index}>
                {image && (
                  <Link to={postContent.path}>
                    <GatsbyImage image={image} alt={posts[0].node.frontmatter.title} className="w-full rounded-md"/>
                  </Link>
                )}
                <Link
                  to={`/updates/${category.toLowerCase()}`}
                  className="text-base opacity-60 md:text-lg xl:text-lg hover:underline"
                >
                  {category}
                </Link>
                <Link to={postContent.path} className="hover:underline">
                  <h2 className="mb-3 text-base font-semibold md:text-lg xl:text-xl">{postContent.title}</h2>
                  <p className="font-mono text-base md:text-base xl:text-lg">{postContent.shortDescription}</p>
                </Link>
              </div>
            );
          })}</div>
          <div class="grid grid-cols-1 xl:grid-cols-2 xl:gap-20">
            <div></div>
            <Newsletter />
          </div>
        </div>
      </div>
      <div className="pt-6 bg-gray md:py-10 xl:py-10">
        <div className="container relative grid w-full grid-cols-1 gap-0 xl:grid-cols-2 md:gap-20 xl:gap-20">
          <div className="hidden xl:block">
            <StaticImage
              src="../images/help-center-hero.png"
              alt="Man"
              width={500}
              quality={100}
              style={{ position: "absolute" }}
              className="z-0 left-10 md:left-0 xl:left-0 bottom-full md:-bottom-10 xl:-bottom-10"
            />
          </div>
          <div className="z-10 flex flex-col w-full gap-3 p-5 text-white bg-black rounded-md shadow-md sm:w-4/6">
            <h3 className="text-2xl">Help center</h3>
            <p>Find out how to use Slashscore like a pro and get quick answers to the most common questions.</p>
            <Link to="/help-center" className="py-2 text-center border-2 rounded-md border-gray">
              Start
            </Link>
          </div>
        </div>
      </div>

      <Versions />
      <Footer />
      <CookieBanner />
    </>
  );
};

export default UpdatesPage;

export const articlesQuery = graphql`
  query ($category: String, $skip: Int, $limit: Int) {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { category: { eq: $category }, type: { eq: "article" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            path
            shortDescription
            author
            title
            category
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;
