import React from "react";
import { Helmet } from "react-helmet";

const SeoUpdates = ({ category }) => {

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <title>{`Slashscore | Updates ${category ? `- ${category}` : " "}`}</title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Bots */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      {/* App /> */}
      <meta
        name="description"
        content="With its unique combination of 10+ integrations, Slashscore is the go-to hub for software developers of all experience levels to tell their story."
      />
      <meta name="theme-color" content="#008f68" />
      <meta
        name="keywords"
        content="Web Developer, Developer, Software Engineer, Front-End Developer, Back-End Developer, Full-Stack Developer, Front-End Engineer, Back-End Engineer, Full-Stack Engineer, MEAN Developer, Full-stack Developer, Mobile Developer, Design Developer, Game Developer, Data Scientist, Big Data Developer, DevOps Developer, CRM Developer, Software Development Engineer in Test, Embedded Developer, WordPress Developer, Security Developer, Blockchain Developer, Salesforce Developer, Software Engineer, Network Engineer, Data Engineer, Machine Learning Engineer, AWS Solutions Architect, QA Engineer, Robotics Engineer, Programmer Analyst, Application Security Engineer, Technical Lead, Intern Software Developer, Product Manager, Director of Engineering, Chief Architect, Software Architect, Engineering Manager, Principal Software Engineer"
      />
      <meta name="author" content="Slashscore" />
      {/* Open Graph data */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Slashscore | We build & scale personal brands of software engineers who inspire & make an impact " />
      <meta property="og:description" content="" />
      <meta property="og:url" content={process.env.HOST} />
      <meta property="og:site_name" content="Slashscore" />
      <meta property="og:image" content={`${process.env.HOST}/slashscore-updates-preview.png`} />
      <meta property="og:image:width" content="2401" />
      <meta property="og:image:height" content="1261" />
      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@slashcoreai" />
      <meta name="twitter:title" content="Slashscore | We build & scale personal brands of software engineers who inspire & make an impact " />
      <meta name="twitter:description" content="The official blog for news, articles, and updates from Slashscore." />
      <meta name="twitter:creator" content="@slashcoreai" />
      {/* Facebook Card data */}
      <meta name="facebook:card" content="summary" />
      <meta name="facebook:site" content="@slashcoreai" />
      <meta name="facebook:title" content="Slashscore | We build & scale personal brands of software engineers who inspire & make an impact " />
      <meta name="facebook:description" content="The official blog for news, articles, and updates from Slashscore." />
      <meta name="facebook:creator" content="@slashcoreai" />
      {/* Linkedin Card data */}
      <meta name="linkedin:card" content="summary" />
      <meta name="linkedin:site" content="@slashcoreai" />
      <meta name="linkedin:title" content="Slashscore | We build & scale personal brands of software engineers who inspire & make an impact " />
      <meta name="linkedin:description" content="The official blog for news, articles, and updates from Slashscore." />
      <meta name="linkedin:creator" content="@slashcoreai" />
      {/* Geo */}
      <meta name="geo.position" content="latitude, longitude" />
      <meta name="geo.placename" content="Place Name" />
      <meta name="geo.region" content="Country Subdivision Code" />
      {/* Rights */}
      <meta name="dcterms.rightsHolder" content="WIN GRAVITY SRL" />
      <meta name="dcterms.rights" content="Statement of copyright" />
      <meta name="dcterms.dateCopyrighted" content="2022" />
      <meta name="copyright" content="WIN GRAVITY SRL" />
    </Helmet>
  );
};
export default SeoUpdates;
