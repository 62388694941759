import React from "react";

const formatDate = (articleDate) => {
  const date = new Date(articleDate.replace(/-/g, "/"));
  const month = date.toLocaleDateString("en-US", {
    month: "short",
  });

  return date.getUTCDate() + " " + month + ". " + date.getUTCFullYear();
};

const VersionInfo = (props) => {
  const { version } = props;
  const { title, releaseDate } = version.frontmatter;

  return (
    <div className="mt-3 relative">
      <h3 className="text-lg md:text-xl xl:text-2xl font-mono mt-5 md:mt-10 xl:mt-10">{title}</h3>
      <h4 className="underline opacity-60 text-sm mb-4">Release Date {formatDate(releaseDate)} </h4>
      <div
        dangerouslySetInnerHTML={{
          __html: version.html,
        }}
        className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-5 md:gap-10 xl:gap-10 version"
      />
      <svg
        className="absolute top-16 left-[-1rem]"
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.669 16.4745C13.3837 17.6519 7.88752 17.3975 7.07463 13.1509C6.7345 11.3732 7.39695 9.49296 8.62364 8.20135C10.0462 6.70389 12.3117 6.56502 12.1908 9.17282C12.1066 10.9775 10.3906 12.545 13.4076 12.9088C15.2851 13.1349 18.0621 11.7296 18.1554 9.65396C18.3666 4.9754 10.2746 4.60854 7.29258 5.41779C1.374 7.02278 2.98196 13.2271 7.04638 16.0586C8.09625 16.7904 11.109 17.9173 11.2465 19.4369C11.4294 21.4523 8.93922 21.0407 7.71866 20.7752C-1.25314 18.8206 -3.2086 3.77841 6.16596 0.942642C10.4232 -0.344669 16.4185 0.984402 18.9842 4.84326C21.3897 8.46126 21.4223 14.7712 16.669 16.4745Z"
          fill="#515696"
        />
      </svg>
      <svg
        className="absolute top-[3.8rem] left-[33%]"
        width={28}
        height={26}
        viewBox="0 0 28 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4702 15.5282C14.0705 14.5739 10.3382 14.8953 10.2565 16.0204C10.135 17.7016 15.1645 17.1866 14.4702 15.5282ZM18.4345 16.1525C20.5496 15.9804 24.0246 14.5426 26.063 15.4114C29.1739 16.7375 25.907 18.7726 24.2383 19.3809C22.54 19.9997 20.0105 19.6709 19.1375 21.448C18.4805 22.7844 20.1039 26.5155 18.0901 25.9157C15.9768 25.2865 16.5914 19.9419 13.2754 21.7435C11.6938 22.6032 12.3391 27.8396 9.68985 25.1642C8.96476 24.4324 8.90151 23.0886 7.94127 22.5429C6.96139 21.9856 5.73223 22.5663 4.6977 22.5177C1.95882 22.3893 1.08638 19.8251 3.86149 18.8082C4.88864 18.4315 8.76028 17.9719 6.41495 16.292C4.93714 15.2338 1.6543 17.4545 0.385245 15.6124C-1.35902 13.082 3.31322 13.0248 4.56816 12.2678C6.69492 10.986 4.93839 7.99174 5.10785 5.85954C5.36755 2.60164 7.25856 3.28247 7.94558 6.03958C8.52823 8.37886 7.80619 11.7461 11.3297 10.8165C17.0678 9.30241 8.13899 2.01175 12.3422 0.146229C15.0221 -1.04338 15.4015 5.38703 15.6096 6.4943C16.3802 10.5811 18.6739 9.62746 22.1489 9.50211C26.2085 9.35586 27.1883 12.3612 22.8894 13.2221C21.5178 13.4967 19.6311 13.1496 18.44 14.024C17.7352 14.5407 16.7664 16.2877 18.4345 16.1525Z"
          fill="#515696"
        />
      </svg>
    </div>
  );
};

export default VersionInfo;
