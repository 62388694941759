import React, { useState } from "react";
import { Link } from "gatsby";

import ThankYouModal from "./ThankYouModal";
import { sendNewsletterEmail } from "../../helpers/sendEmail";

const Newsletter = ({ variant }) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) return;
    // Send email through Mailchimp
    const result = await sendNewsletterEmail(email);

    //check errors
    if (result.result === "error") {
      if (result.msg.includes("is already subscribed")) setShowModal(true);
      else
        setErrors({
          mainError: "Something went wrong. Please try again in about 5 minutes.",
        });
    } else if (result.result === "success") setShowModal(true);
  };

  return (
    <div className={`mt-5 ${variant === "article" ? "w-full md:w-full xl:w-3/4" : ""}`}>
      <h2 className="mb-5 text-xl md:text-2xl xl:text-3xl">Stay updated by signing up for our newsletter</h2>
      <div className={`flex ${variant === "article" ? "gap-5 flex-col md:flex-col xl:flex-row" : "flex-col"}`}>
        <p className="mb-5 font-mono leading-7">
          Here are our most recent updates. But wait, there's more! Dive deeper into our blog posts for plenty of fascinating
          insights. Rest assured that we will keep you informed of all the exciting advances in the field of software
          engineering.
        </p>

        <div>
          <form className="flex justify-start mb-2" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="youremail@domain.com"
              name="email"
              className={`${
                variant === "article" ? "w-full" : "w-3/5"
              } text-black p-2 border-black/20 border-t-[1px] border-b-[1px] border-l-[1px]`}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors ? <div className="text-red">{errors}</div> : null}

            <button
              type="submit"
              className="bg-primary text-white p-2 border-black/10 border-t-[1px] border-b-[1px] border-r-[1px]"
            >
              <svg width="30" height="16" viewBox="0 0 52 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M51.2071 8.70711C51.5976 8.31658 51.5976 7.68342 51.2071 7.29289L44.8431 0.928932C44.4526 0.538408 43.8195 0.538408 43.4289 0.928932C43.0384 1.31946 43.0384 1.95262 43.4289 2.34315L49.0858 8L43.4289 13.6569C43.0384 14.0474 43.0384 14.6805 43.4289 15.0711C43.8195 15.4616 44.4526 15.4616 44.8431 15.0711L51.2071 8.70711ZM0 9H50.5V7H0V9Z"
                  fill="black"
                />
              </svg>
            </button>
          </form>

          <p className="font-mono text-xs opacity-60">
            By submitting this newsletter request, I consent to Slashscore.com sending me marketing communication via
            email. I may opt out at any time. View Slashscore&apos;s&nbsp;
            <Link to="/privacy-policy" target="_blank" rel="noreferrer" className="underline">
              Privacy Policy.
            </Link>
          </p>
        </div>

        {showModal ? <ThankYouModal open={showModal} close={true} handleClose={() => setShowModal(false)} /> : null}
      </div>
    </div>
  );
};

export default Newsletter;
