import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import VersionInfo from "./VersionInfo";

const Versions = () => {
  const [showAllVersions, setShowAllVersions] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 100
        sort: { order: DESC, fields: [frontmatter___releaseDate] }
        filter: { frontmatter: { type: { eq: "version" } } }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              releaseDate
            }
          }
        }
      }
    }
  `);

  const renderedVersions = data.allMarkdownRemark.edges.map((version, i) => {
    return <VersionInfo version={version.node} key={i} />;
  });

  return (
    <div className="border-black border-b-2">
      <div className="container h-fit w-full gap-8 py-5 md:py-10 xl:py-10">
        <h2 className="text-xl md:text-2xl xl:text-3xl">New in Slashscore</h2>
        {showAllVersions ? renderedVersions : <VersionInfo version={data.allMarkdownRemark.edges[0].node} />}

        <div className="flex justify-end mt-5">
          <button onClick={() => setShowAllVersions(!showAllVersions)} className="p-2 border-black border-2 rounded-md w-1/2 md:w-1/3 xl:w-1/3 mt-5 md:mt-0 xl:mt-0">
            {showAllVersions ? "Hide all versions" : "See all versions"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Versions;
